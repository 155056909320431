<template>
    <div id="layout" class="wrapper">
        <div class="container">
            <div class="top">
                
            </div>
            <div class="login">
                <a :href="loginUrl">
                    <img src="~@/assets/btn_google_signin.png"/>
                </a>
            </div>
            <div class="footer">
                Copyright &copy; 2022 GameBattleLab.com
            </div>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        loginUrl: function() {
            let apiDomain = process.env.VUE_APP_API_DOMAIN;
            return `https://auth.${apiDomain}/login`;
        }
    }
}
</script>

<style lang="less" scoped>
#layout.wrapper {
    height: 100vh;
    .container {
        width: 100%;
        min-height: 100%;
        background-color: lightblue;
        position: relative;

        .top {
            text-align: center;
        }
        .login {
            padding-top: 30vh;
            text-align: center;

        }
        .footer {
            position: absolute;
            width: 100%;
            bottom: 0;
            padding: 0, 32px;
            margin-bottom: 24px;
            text-align: center;
        }
    }
}
</style>